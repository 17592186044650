body {
  margin: 0px !important;
  padding: 0px !important;
  background: #f5f5f5 !important;
  font-family: "Poppins", sans-serif !important;
}
*:focus {
  outline: none !important;
}

.login_background {
  background: #fff url("./assets/images/login_bg.svg") no-repeat bottom right;
  height: 100vh;
}
.login_wrapper {
  display: flex;
}
.login_wrapper .login_area {
  background: #00a159;
  border: 1px solid rgba(202, 216, 211, 0.6);
  border-radius: 6px;
  padding: 21px;
  width: 400px;
  margin: 100px auto 0px;
}
.login_wrapper .login_area .Logo_area {
  margin: 0px auto 40px;
  text-align: center;
}
.login_wrapper .login_area .Logo_area .title {
  color: #fff;
  font-size: 17px;
  margin-top: 10px;
}
.login_wrapper .login_area .form {
}
.login_wrapper .login_area .form .form_group {
  height: 55px;
}
.login_wrapper .login_area .form .form_group .form-ele_wrapper {
  border-bottom: 1px solid #cad8d3;
}
.login_wrapper .login_area .form .form_group input[type="text"],
.login_wrapper .login_area .form .form_group input[type="password"] {
  background: none;
  color: #fff;
  border: none;
  padding-left: 10px;
  // font-family: Helvetica;
  font-size: 14px;
  letter-spacing: 0;
  width: 70%;
}

.validation_error {
  font-size: 12px;
  color: red;
}

.width_65 {
  width: 65% !important;
}

.login_wrapper .login_area .form ::-webkit-input-placeholder {
  /* Edge */

  color: #ffffff;
}

.login_wrapper .login_area .form :-ms-input-placeholder {
  /* Internet Explorer */
  color: #ffffff;
}

.login_wrapper .login_area .form ::placeholder {
  color: #ffffff;
}
.login_wrapper .login_area .form .error_message {
  display: block;
  color: #f3bfbf;
  font-size: 12px;
  padding-left: 32px;
}

.login_wrapper .login_area .form .forgot_pass a {
  font-size: 11px;
  color: #fff;
}
.login_wrapper .login_area .form .login_btn {
  // font-family: Gilroy;
  font-size: 22px;
  color: #ffffff;
  letter-spacing: 0;
  margin: 20px auto 0px;
  text-align: center;
}
.login_wrapper .login_area .form .login_btn .login_txt {
  margin-right: 10px;
  font-weight: 500;
  background: transparent;
  color: #fff;
  border: none;
  box-shadow: none;
}
.login_img:active {
  border-radius: 50%;
  box-shadow: 0 0 0 3px #1ece1e;
}

.clicked_button {
  border-radius: 50%;
  box-shadow: 0 0 0 3px lightgrey;
}

.pointer {
  cursor: pointer;
}

.enable_button {
  height: 30px;
  width: 30px;
  cursor: pointer;
}

.disable_button:active {
  border-radius: 50%;
  box-shadow: 0 0 0 3px lightgrey;
}

.enable_button:active {
  border-radius: 50%;
  box-shadow: 0 0 0 3px lightgrey;
}

.fix_header {
  background: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.06);
  width: 100%;
  height: 76px;
  position: fixed;
  z-index: 1;
}
.fix_header .header_log {
  margin-left: 10px;
  line-height: 76px;
  // margin-top: 22px;
}
.user_wrapper {
  text-align: right;
  margin-top: 22px;
}

.fix_header .user_area,
.fix_header .user_area:hover,
.fix_header .user_area:active {
  color: #3a3a3c !important;
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: 0 0 0 0.2rem rgba(130, 138, 145, 0) !important;
}
.user_img {
  margin-right: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  text-align: center;
  background: #00a159;
  display: inline-flex;
  justify-content: center;
  line-height: 30px;
  color: #fff;
}
.profile_dropdown {
  outline: none !important;
  box-shadow: none !important;
  color: #3a3a3c !important;
  background-color: #fff !important;
  border: none !important;
}
.profile_dropdown_menu {
  min-width: 140px !important;
}
.sidebar_link {
  color: #212529;
  text-decoration: none !important;
}

.content_wrapper {
  margin-top: 76px;
  display: inline-flex;
  width: 100%;
}

.content_wrapper .sidebar {
  width: 180px;
  height: calc(100vh - 76px);
  background: #fff;
  display: inline-flex;
  position: fixed;
}
.content_wrapper .sidebar .nav {
  margin-top: 100px;
}
.content_wrapper .sidebar .nav .nav_item {
}
.content_wrapper .sidebar .nav .nav_item .active {
  -webkit-border-top-right-radius: 8px;
  -webkit-border-bottom-right-radius: 8px;
  -moz-border-radius-topright: 8px;
  -moz-border-radius-bottomright: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #00a159;
  width: 4px;
  height: 30px;
  display: inline-flex;
}
.content_wrapper .sidebar .nav .nav_item .item {
  vertical-align: top;
  font-size: 13px;
  margin-left: 15px;
  margin-top: 5px;
  display: inline-block;
}
.content_wrapper .page_content {
  vertical-align: top;
  width: calc(100vw - 180px);
  padding: 40px;
  margin-left: 180px;
}
.content_wrapper .page_content h1 {
  // font-family: Gilroy;
  font-weight: 500;
  font-size: 17px;
  color: #235862;
  margin: 0px 0px 30px 0px;
}
.content_wrapper .page_content .tablecontent_wrapper {
  background: #ffffff;
  box-shadow: 0 0 10px 0 rgba(214, 214, 214, 0.27);
  border-radius: 8px;
}
.filter_wrapper {
  padding: 15px 20px;
}
.search_box {
  background: #ffffff;
  border: 1px solid rgba(58, 58, 60, 0.31);
  border-radius: 4px;
  // font-family: Helvetica;
  font-size: 13px;
  color: rgba(58, 58, 60, 0.39);
  letter-spacing: 0;
  height: 36px;
  width: 272px;
  display: inline-flex;
  margin-right: 30px;
}
.search_box input[type="text"] {
  background: transparent;
  border: none;
  height: 36px;
  padding-left: 10px;
  width: 200px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #00a159 inset !important;
}
input:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
  caret-color: #fff;
}

.modal-body ::-webkit-input-placeholder {
  font-size: 14px !important;
}

.modal-body :-ms-input-placeholder {
  font-size: 14px !important;
}

.modal-body ::placeholder {
  font-size: 14px !important;
}

.search_box ::-webkit-input-placeholder {
  /* Edge */

  color: #303034;
}

.search_box :-ms-input-placeholder {
  /* Internet Explorer */
  color: #303034;
}

.search_box ::placeholder {
  color: #303034;
}

.font-14 {
  font-size: 14px !important;
}

.radio_wrapper {
  display: inline-flex;
}

.radio_wrapper [type="radio"]:checked,
.radio_wrapper [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.radio_wrapper [type="radio"]:checked + label,
.radio_wrapper [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
  color: #666;
}
.radio_wrapper [type="radio"]:checked + label:before,
.radio_wrapper [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  border: 1px solid #ddd;
  border-radius: 100%;
  background: #fff;
}
.radio_wrapper [type="radio"]:checked + label:after,
.radio_wrapper [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #00a159;
  position: absolute;
  top: 4px;
  left: 4px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.radio_wrapper [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.radio_wrapper [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
button.send_notification {
  background: #ff9354;
  border-radius: 4px;
  // font-family: Helvetica;
  font-size: 13px;
  color: #ffffff;
  height: 36px;
  border: none;
  padding: 0px 20px;
}
.notification_btn {
  display: inline-flex;
  float: right;
}

/* This css is for normalizing styles. You can skip this. */
*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.form-group {
  display: block;
  margin-bottom: 1px !important;
}

.form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group label {
  position: relative;
  cursor: pointer;
  margin-bottom: 0px !important;
}

.form-group label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 0px solid #0079bf;
  box-shadow: none;
  // 0 1px 2px rgba(0, 0, 0, 0.05),
  //   inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 8px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 0px;
  background: #e4ebe9;
}
.form-group input:checked + label::before {
  background: #00a159;
  box-shadow: none;
}

.form-group input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 0px;
  left: 5px;
  width: 6px;
  height: 12px;
  margin-top: 4px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.popup_textarea {
  height: 200px;
  padding: 10px;
  width: 100%;
}

.loader {
  display: inline-block;
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid #00a159;
  width: 75px;
  height: 75px;
  margin: 20px;
  animation: spin 2s linear infinite;
}

.loader_container {
  height: 300px !important;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.toast_style {
  height: 80px;
  width: 260px;
  position: fixed;
  top: 30px;
  right: 2px;
  background-color: #00a159 !important;
  color: #fff;
  z-index: 1;
}

.table_wrapper table {
  width: 100%;
}
.table_wrapper table thead {
  background-color: #f5f5f5;
}
.table_wrapper table thead tr {
  border-bottom: 1px solid #e9e9e9;
}

.table_wrapper table thead tr td {
  color: #235862;
  font-size: 17px;
  padding: 0px 10px 0px 10px;
  border-right: 1px solid #e9e9e9;
  height: 44px;
}
.table_wrapper table thead tr td.user_name {
  width: 250px;
}
.table_wrapper table thead tr td.email_address {
  width: 350px;
}
.table_wrapper table thead tr td .shorting_ic {
  float: right;
  // margin-top: 5px;
  cursor: pointer;
}

.table_wrapper table thead tr td:first-child {
  width: 57px;
  text-align: center;
}

.table_wrapper table tbody tr {
  border-bottom: 1px solid #e9e9e9;
}
.table_wrapper table tbody tr:hover {
  background: #f5f5f5;
}
.table_wrapper table tbody tr td {
  font-size: 13px;
  color: #235862;
  border-right: 1px solid #e9e9e9;
  padding: 0px 10px 0px 10px;
  height: 44px;
}

.table_wrapper table tbody tr td.action_ic {
  text-align: right;
}

.table_wrapper table tbody tr td:first-child {
  text-align: center;
}

.login_link {
  text-align: center;
  color: #fff;
  text-decoration: underline;
}

.pagination_wrapper {
  font-size: 12px;
  color: #3a3a3c;
  font-weight: 500;
  margin-top: 20px;
}
.show_number {
  border: 0px solid #fff !important;
  padding: 5px !important;
  border-radius: 4px !important;
  margin-left: 10px !important;
}
.page_number {
  border: 0px solid #fff;
  padding: 5px;
  border-radius: 4px;
  margin-left: 10px;
  width: 30px;
}
.send_notification .modal-header {
  border-bottom: 0px solid #dee2e6 !important;
}
.send_notification .modal-header .modal-title {
  margin: 0px auto;
}
.send_notification textarea {
  height: 150px;
  width: 100%;
  background: #fbfbfb;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
}
.send_notification .modal-footer {
  border-top: 0px solid #dee2e6;
  justify-content: center;
}
.send_notification .close {
  position: absolute;
  top: 22px;
  right: 13px;
}

.pagination_number_box {
  display: inline-block !important;
  width: inherit !important;
  height: inherit !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: normal !important;
  background-color: #fff !important;
  background-clip: border-box !important;
}

.notify_text {
  padding-top: 5px;
  margin-right: 5px;
  color: #666;
}
